<template>
  <div class="chart-box">
    <div>电芯一致性（展示16个电芯电压近15分钟轨迹图）</div>
    <Chart :cdata="config" />
  </div>
</template>

<script>
import { formatTime } from "../../../utils/index.js";
import Chart from "./chart.vue";
export default {
  props: {
    cdata: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.setData(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      config: {},
    };
  },
  components: {
    Chart,
  },
  mounted() {},
  methods: {
    // 根据自己的业务情况修改
    setData(newData) {
      let configData = {
        series1: [],
        series2: [],
        series3: [],
        series4: [],
        series5: [],
        series6: [],
        series7: [],
        series8: [],
        series9: [],
        series10: [],
        series11: [],
        series12: [],
        series13: [],
        series14: [],
        series15: [],
        series16: [],
        category: [],
        min: null,
        max: null,
      };
      let numArr = [];
      if (newData.length) {
        for (let i = 0; i < newData.length; i++) {
          let p = newData[i];
          for (let j = 0; j < 16; j++) {
            let name = "series" + (j + 1);
            let value = "batteryVoltage" + (j + 1);
            configData[name].push(p[value]);
            numArr.push(p[value]);
          }
          configData.category.push(formatTime(p.ts, "HH:mm:ss"));
          configData.min = Math.min(...numArr);
          configData.max = Math.max(...numArr);
        }
        console.log(configData);
      }

      this.config = {
        ...configData,
      };
    },
    setTime: function (time, num) {
      let arr = [];
      for (let i = 0; i < num; i++) {
        if (i == 0) {
          arr.push((time + 1) % 24);
        } else {
          arr.push((arr[i - 1] + 1) % 24);
        }
      }
      arr.forEach((e, i) => {
        arr[i] = e + ":00";
      });
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-box {
  padding: 30px 30px 20px 30px;
}
</style>
