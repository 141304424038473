<template>
  <dv-border-box-13 class="chart-box">
    <div>新能源充电</div>
    <Chart :cdata="config" />
  </dv-border-box-13>
</template>

<script>
import Chart from "./chart.vue";
import Moment from "moment";
export default {
  props: {
    cdata: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.setData(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      config: {},
    };
  },
  components: {
    Chart,
  },
  mounted() {
    // this.getLasttime();
  },
  methods: {
    // 根据自己的业务情况修改
    setData(newData) {
      let category = [];
      // 市电
      let series1 = newData.inverterChargeCapacity.map((p) => {
        category.push(Moment(p.ts).format("MM-DD"));
        return p.sumVal;
      });
      // 太阳能
      let series2 = newData.pvChargeCapacity.map((p) => {
        return p.sumVal;
      });
      // 风能
      let series3 = newData.windChargeCapacity.map((p) => {
        return p.sumVal;
      });
      this.config = {
        series1,
        series2,
        series3,
        category,
      };
      console.log("config", this.config);
    },
    getLasttime: function () {
      let date = new Date();
      let hour = date.getHours();
      let timeList = this.setTime(hour, 24);
      this.setData(timeList);
    },
    setTime: function (time, num) {
      let arr = [];
      for (let i = 0; i < num; i++) {
        if (i == 0) {
          arr.push((time + 1) % 24);
        } else {
          arr.push((arr[i - 1] + 1) % 24);
        }
      }
      arr.forEach((e, i) => {
        arr[i] = e + ":00";
      });
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-box {
  padding: 30px 30px 20px 30px;
}
</style>
