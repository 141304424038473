<template>
  <div class="chart-box">
    <div>告警统计（近30日）</div>
    <Chart :cdata="config" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import Moment from "moment";

export default {
  props: {
    cdata: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.setData(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      config: {},
    };
  },
  components: {
    Chart,
  },
  mounted() {
    // this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData(newData) {
      let category = [];
      // 故障
      let series1 = newData.faultAlarmList.map((p) => {
        let dateString = Moment(p.ts).format("MM/DD");
        category.push(dateString);
        return p.num;
      });
      // 提醒
      let series2 = newData.reminderAlarmList.map((p) => {
        return p.num;
      });
      // 严重
      let series3 = newData.seriousAlarmList.map((p) => {
        return p.num;
      });
      // if (!category.length) {
      //   console.log("cz-category111", category.length);
      //   for (let i = 0; i < 7; i++) {
      //     category.push(
      //       Moment()
      //         .subtract(7 - i - 1, "days")
      //         .format("MM/DD")
      //     );
      //   }
      // }
      this.config = {
        // series1: series1.length ? series1 : [0, 0, 0, 0, 0, 0, 0],
        // series2: series2.length ? series2 : [0, 0, 0, 0, 0, 0, 0],
        // series3: series3.length ? series3 : [0, 0, 0, 0, 0, 0, 0],
        // category,
        series1,
        series2,
        series3,
        category,
      };
      console.log("configxxx", this.config);
    },
    setTime: function (time, num) {
      let arr = [];
      for (let i = 0; i < num; i++) {
        if (i == 0) {
          arr.push((time + 1) % 24);
        } else {
          arr.push((arr[i - 1] + 1) % 24);
        }
      }
      arr.forEach((e, i) => {
        arr[i] = e + ":00";
      });
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-box {
  padding: 30px 30px 20px 30px;
}
</style>
