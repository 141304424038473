<template>
  <dv-border-box-13 class="chart-box">
    <div>SOC/温度</div>
    <div class="ring-box">
      <el-progress
        type="circle"
        :percentage="config.batterySoc || 0"
        define-back-color="#131920"
        color="#2a61c8"
        :show-text="false"
        class="progress"
      ></el-progress>
      <div class="progress-text">
        <div class="progress-num">
          {{ config.batterySoc ? config.batterySoc + "%" : "-" }}
        </div>
        <div class="progress-sub">SOC</div>
      </div>
      <el-progress
        type="circle"
        :percentage="config.envTemp || 0"
        define-back-color="#131920"
        color="#2a61c8"
        :show-text="false"
      ></el-progress>
      <div class="progress-text2">
        <div class="progress-num">
          {{ config.envTemp ? config.envTemp + "℃" : "-" }}
        </div>
        <div class="progress-sub">温度</div>
      </div>
    </div>

    <div class="progress-box">
      <!-- <div class="progress-box-title">充电总功率</div>
      <div class="progress-box-item">
        <el-progress
          :percentage="config.inputTotalPower"
          color="#8acfae"
          define-back-color="#14202e"
        ></el-progress>
      </div>
      <div class="progress-box-title">放电总功率</div>
      <div class="progress-box-item">
        <el-progress
          :percentage="config.outputTotalPower"
          color="#8acfae"
          define-back-color="#14202e"
        ></el-progress>
      </div> -->
      <dv-capsule-chart
        :config="config.progress"
        style="width: 100%; height: 100px"
      />
    </div>
  </dv-border-box-13>
</template>
<script>
export default {
  props: {
    cdata: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.setData(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      config: {},
    };
  },

  mounted() {},
  methods: {
    setData(newData) {
      this.config = {
        ...newData,
        progress: {
          data: [
            {
              name: "充电总功率",
              value: newData.inputTotalPower,
            },
            {
              name: "放电总功率",
              value: newData.outputTotalPower,
            },
          ],
          showValue: true,
          unit: "W",
        },
      };
    },
    format(percentage) {
      let tex = "SOC";
      return percentage + "%\n" + tex;
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-box {
  width: 100%;
  height: 320px;
  box-sizing: border-box;
  padding: 30px 30px 20px 30px;
}
.ring-box {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  .progress {
    margin-right: 10px;
  }
  .progress-text {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    text-align: center;
    .progress-num {
      font-size: 26px;
      color: white;
    }
    .progress-sub {
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .progress-text2 {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    text-align: center;
    .progress-num {
      font-size: 26px;
      color: white;
    }
    .progress-sub {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
.progress-box {
  margin-top: 20px;
  .progress-box-title {
    margin-top: 10px;
  }
  .progress-box-item {
    margin-top: 10px;
  }
}
// ::v-deep .unit-label {
//   display: none;
// }
</style>
