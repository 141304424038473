<template>
  <dv-border-box-13 class="chart-box">
    <div style="margin-bottom: 10px">故障告警</div>
    <!-- <div class="content">
      <div class="content-item">
        <div class="content-item-left">
          <i class="el-icon-warning tag"></i>
        </div>
        <div class="content-item-right">
          大事不好了，有人阳了！赶紧消杀
        </div>
      </div>
      <div class="content-item">
        <div class="content-item-left">
        </div>
        <div class="content-item-right">
          <div class="time">2022-12-19 13:23</div>
          <div>今天的我依然坚挺，帅的人抵抗力高</div>
        </div>
      </div>
      <div class="content-item">
        <div class="content-item-left">
        </div>
        <div class="content-item-right">
          <div class="time">2022-12-19 13:23</div>
          <div>相约决赛圈，大吉大利，这局吃鸡</div>
        </div>
      </div>
    </div> -->
    <!-- <dv-scroll-board :config="config" style="width: 100%; height: 200px" /> -->
    <dv-scroll-board :config="config" style="width: 100%; height: 510px" />
  </dv-border-box-13>
</template>
<script>
import Moment from "moment";

export default {
  props: {
    cdata: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.setData(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      config: {},
    };
  },

  mounted() {},
  methods: {
    setData(newData) {
      let data = newData.map((p) => {
        let dateString = Moment(p.ts).format('YYYY-MM-DD HH:mm:ss');
        return [p.codeName, dateString];
      });
      this.config = {
        header: ["告警内容", "告警时间"],
        data,
        headerBGC: "#72c7d7",
        rowNum: 8,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-box {
  width: 100%;
  // height: 280px;
  height: 600px;
  box-sizing: border-box;
  padding: 30px 30px 20px 30px;
  margin-bottom: 20px;
}
.content {
  // margin-top: 10px;
}
.content-item {
  display: flex;
  align-items: center;
}
.tag {
  font-size: 26px;
  color: #bd332b;
  // background: white;
}
.content-item-left {
  width: 50px;
  display: flex;
  align-items: center;
}
.content-item-right {
  padding: 15px 0;
  border-bottom: 1px solid #1b1f27;
  flex: 1;
}
.time {
  font-size: 14px;
  color: rgb(104, 102, 102);
  margin-bottom: 8px;
}
</style>
