<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">{{ mac }}能源数据</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <div class="content">
          <el-row :gutter="20">
            <el-col :span="5">
              <div>
                <LeftOne :cdata="realTimeData" />
              </div>
              <div style="margin: 20px 0">
                <LeftTwo :cdata="chargeTrendData" />
              </div>
              <div>
                <LeftThree :cdata="equipmentInfo" />
              </div>
            </el-col>
            <el-col :span="14">
              <div>
                <CenterOne />
              </div>
              <div class="center-bottom-box">
                <dv-border-box-13 style="padding-right: 30px">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <CenterTwo :cdata="warningTrendData" />
                    </el-col>
                    <!-- <el-col :span="8">
                      <CenterThree />
                    </el-col> -->
                    <el-col :span="12">
                      <CenterThree :cdata="cellVoltageTrack" />
                    </el-col>
                  </el-row>
                </dv-border-box-13>
              </div>
            </el-col>
            <el-col :span="5">
              <div>
                <RightOne :cdata="faultWarningData" />
              </div>
              <!-- <div style="margin: 20px 0">
                <RightTwo />
              </div> -->
              <div>
                <RightThree />
              </div>
            </el-col>
          </el-row>
          <!-- <div class="content-left">
            <div>
              <LeftOne />
            </div>
            <div style="margin:20px 0">
              <LeftTwo />
            </div>
            <div>
              <LeftThree />
            </div>
          </div>
          <div class="content-center">
            <div>
              <CenterOne />
            </div>
          </div>
          <div class="content-right"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import netConstants from "../net/netConstants";
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
import db from "../utils/localstorage.js";
import LeftOne from "../components/equipment/leftOne";
import LeftTwo from "../components/equipment/leftTwo";
import LeftThree from "../components/equipment/leftThree";
import CenterOne from "../components/equipment/centerOne";
import CenterTwo from "../components/equipment/centerTwo";
import CenterThree from "../components/equipment/centerThree";
import RightOne from "../components/equipment/rightOne";
// import RightTwo from "../components/equipment/rightTwo";
import RightThree from "../components/equipment/rightThree";
import Moment from "moment";

export default {
  mixins: [drawMixin],
  data() {
    return {
      mac: "",
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
      equipmentInfo: {},
      cellVoltageTrack: [],
      warningTrendData: {
        faultAlarmList: [],
        reminderAlarmList: [],
        seriousAlarmList: [],
      },
      chargeTrendData: {
        inverterChargeCapacity: [],
        pvChargeCapacity: [],
        windChargeCapacity: [],
      },
      realTimeData: {
        batterySoc: 0,
        envTemp: 0,
        inputTotalPower: 0,
        outputTotalPower: 0,
      },
      faultWarningData: [],
      axiosTimer: "",
      axiosTimerHour: "",
    };
  },
  components: {
    LeftOne,
    LeftTwo,
    LeftThree,
    CenterOne,
    CenterTwo,
    CenterThree,
    RightOne,
    // RightTwo,
    RightThree,
  },
  mounted() {
    this.mac = this.$route.params.id;
    this.getToken();
    this.timeFn();
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing);
    clearInterval(this.axiosTimer);
    clearInterval(this.axiosTimerHour);
  },
  methods: {
    getToken() {
      this.$post("aiot-auth/oauth/token?grant_type=client_credentials").then(
        (res) => {
          db.save("TOKEN_TYPE", res.data.token_type);
          db.save("ACCESS_TOKEN", res.data.access_token);
          this.getEquipmentInfo();
          this.getCellVoltageTrack();
          this.get7daysWarningTrend();
          this.get7daysChargeTrend();
          this.getRealTimeData();
          this.getFaultWarning();
          this.wheelAxios();
        }
      );
    },
    // 16个电芯电压近15分钟轨迹
    getCellVoltageTrack() {
      this.$get(netConstants.api_cellVoltageTrack, { mac: this.mac }).then(
        (res) => {
          if (res.data.code == 200) {
            this.cellVoltageTrack = res.data.data;
          }
        }
      );
    },
    // 设备信息
    getEquipmentInfo() {
      this.$get(netConstants.api_equipmentInfo, {
        mac: this.mac,
      }).then((res) => {
        if (res.data.code == 200) {
          this.equipmentInfo = res.data.data;
        }
      });
    },
    // 单设备近7日告警趋势
    get7daysWarningTrend() {
      let timezoneOffset = new Date().getTimezoneOffset();
      if (timezoneOffset < 0) {
        timezoneOffset = 24 * 60 + timezoneOffset;
      }
      let params = {
        mac: this.mac,
        startTime: Moment().subtract(30, "days").startOf("day").valueOf(),
        endTime: Moment().endOf("day").valueOf(),
        period: 86400,
        timezoneOffset: timezoneOffset,
      };

      this.$get(netConstants.api_warningTrend, params).then((res) => {
        if (res.data.code == 200) {
          this.warningTrendData = res.data.data;
        }
      });
    },
    // 单设备近7日新能源充电趋势
    get7daysChargeTrend() {
      let params = {
        startTime: Moment().subtract(6, "days").startOf("day").valueOf(),
        endTime: Moment().endOf("day").valueOf(),
        period: 86400,
        powerMode: 1,
        mac: this.mac,
      };
      this.$get(netConstants.api_chargeLast7dayCharge, params).then((res) => {
        if (res.data.code == 200) {
          this.chargeTrendData = res.data.data;
        }
      });
    },
    // 实时数据(soc/温度,功率等)
    getRealTimeData() {
      this.$get(netConstants.api_realTimeData, {
        mac: this.mac,
      }).then((res) => {
        if (res.data.code == 200) {
          this.realTimeData = res.data.data;
        }
      });
    },
    // 近10条故障告警
    getFaultWarning() {
      this.$get(netConstants.api_faultWarning, {
        mac: this.mac,
        num: 10,
      }).then((res) => {
        if (res.data.code == 200) {
          this.faultWarningData = res.data.data;
        }
      });
    },
    wheelAxios() {
      this.axiosTimer = setInterval(() => {
        // this.getEquipmentInfo();
        this.getCellVoltageTrack();
        // this.get7daysWarningTrend();
        // this.get7daysChargeTrend();
        this.getRealTimeData();
        this.getFaultWarning();
      }, 20000);

      this.axiosTimerHour = setInterval(() => {
        // this.getEquipmentInfo();
        // this.getCellVoltageTrack();
        this.get7daysWarningTrend();
        this.get7daysChargeTrend();
        // this.getRealTimeData();
        // this.getFaultWarning();
      }, 3600000);
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/equipment.scss";
</style>
