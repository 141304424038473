<template>
  <dv-border-box-13 class="chart-box">
    <div class="content">
      <div>电池健康状态</div>
      <div class="info-box">
        <div>
          <dv-charts :option="option" style="width:150px;height:150px;" />
        </div>
        <div>
          <div class="info-item">
            <div>&gt;=80%以上</div>
            <div class="info-tag">健康</div>
          </div>
          <div class="info-item">
            <div>60%~80%之间</div>
            <div class="info-tag">良好</div>
          </div>
          <div class="info-item">
            <div>&lt;=60%以下</div>
            <div class="info-tag">糟糕</div>
          </div>
        </div>
      </div>
    </div>
  </dv-border-box-13>
</template>
<script>
export default {
  data() {
    return {
      option: {
        series: [
          {
            type: "gauge",
            data: [
              {
                name: "itemA",
                value: 55,
                gradient: ["#d94235", "#e0b33e", "#6f9a6a", "#2d6bd5"],
                localGradient: false,
              },
            ],
            axisLabel: {
              show: true,
              formatter: "{value}%",
              style: {
                fill: "#d3d6dd",
              },
            },
            arcLineWidth: 10,
            radius: "90%",
            pointer: {
              style: {
                scale: [0.5, 0.5],
              },
            },
          },
        ],
      },
    };
  },

  mounted() {},
  methods: {
    format(percentage) {
      let tex = "SOC";
      return percentage + "%\n" + tex;
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-box {
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  padding: 30px 30px 20px 30px;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}
.info-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.info-item {
  padding: 10px 0;
}
.info-tag {
  font-size: 14px;
  color: rgb(104, 102, 102);
}
</style>
