<template>
  <dv-border-box-13 class="chart-box">
    <div>设备信息</div>
    <div class="info-box">
      <div>
        <span class="info-box-label">产品名称</span>
        <span>{{ config.productName }}</span>
      </div>
      <div>
        <span class="info-box-label">设备MAC</span>
        <span>{{ config.mac }}</span>
      </div>
      <div>
        <span class="info-box-label">激活时间</span>
        <span>{{ getTimeZone(config.activateTime) }}</span>
      </div>
      <div>
        <span class="info-box-label">所有者</span>
        <span>{{ config.deviceOwner }}</span>
      </div>
    </div>
  </dv-border-box-13>
</template>
<script>
import Moment from "moment";
export default {
  props: {
    cdata: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    getTimeZone() {
      return (time) => {
        if (time) {
          if (typeof time == "number") {
            let d = new Date(time);
            return Moment(d).format("YYYY-MM-DD HH:mm:ss");
          } else {
            var d = new Date(time);
            var localTime = d.getTime();
            var localOffset = d.getTimezoneOffset() * 60000;
            var utc = localTime + localOffset;
            var offset = 16;
            var userTimezoneTime = utc + 3600000 * offset;
            var nd = new Date(userTimezoneTime);
            return Moment(nd).format("YYYY-MM-DD HH:mm:ss");
          }
        } else {
          return "-";
        }
      };
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.setData(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      config: {},
    };
  },

  mounted() {},
  methods: {
    setData(newData) {
      this.config = newData;
    },
    format(percentage) {
      let tex = "SOC";
      return percentage + "%\n" + tex;
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-box {
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  padding: 30px 30px 20px 30px;
}
.info-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 230px;
  padding-top: 30px;
  box-sizing: border-box;
  .info-box-label {
    display: inline-block;
    width: 100px;
  }
}
</style>
