<template>
  <dv-border-box-1 class="chart-box">
    <img
      src="https://s3.gendome.net/image/prod/background/cg-0013.webp"
      alt=""
    />
  </dv-border-box-1>
</template>
<script>
export default {
  data() {
    return {
      config: {
        data: [
          {
            name: "SOC",
            value: 54,
          },
          {
            name: "ddd",
            value: 23,
          },
        ],
        lineWidth: 6,
        radius: "80%",
        activeRadius: "90%",
      },
    };
  },

  mounted() {},
  methods: {
    format(percentage) {
      let tex = "SOC";
      return percentage + "%\n" + tex;
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-box {
  width: 100%;
  height: 600px;
  box-sizing: border-box;
  overflow: hidden;
  // padding: 20px;
  img {
    width: 100%;
    height: 100%;
    scale: 1.5;
  }
}
</style>
