<template>
  <div>
    <Echart :options="options" height="230px" width="100%"></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC",
              },
            },
          },
          legend: {
            type: "scroll",
            data: ["严重告警", "故障通知", "提醒通知"],
            textStyle: {
              color: "#B4B4B4",
            },
            top: "20px",
            right: "10px",
          },
          grid: {
            top: "60px",
            left: "40px",
            right: "10px",
            bottom: "20px",
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: [
            {
              splitLine: {
                show: true,
                lineStyle: {
                  color: "rgba(32, 67, 110,0.5)",
                },
              },
              axisLine: {
                show: false,
              },
              axisLabel: {
                formatter: "{value} ",
              },
            },
            // {
            //   splitLine: { show: false },
            //   axisLine: {
            //     lineStyle: {
            //       color: "#B4B4B4",
            //     },
            //   },
            //   axisLabel: {
            //     formatter: "{value} ",
            //   },
            // },
          ],
          series: [
            {
              name: "严重告警",
              type: "line",
              symbol: "none",
              itemStyle: {
                normal: {
                  color: "#5dc8c3",
                },
              },
              areaStyle: {
                color: "rgba(95, 205, 195, 0.5)",
              },
              data: newData.series3,
            },
            {
              name: "故障通知",
              type: "line",
              symbol: "none",
              itemStyle: {
                normal: {
                  color: "#3d8ddf",
                },
              },
              areaStyle: {
                color: "rgba(64, 145, 225, 0.5)",
              },
              data: newData.series1,
            },
            {
              name: "提醒通知",
              type: "line",
              symbol: "none",
              itemStyle: {
                normal: {
                  color: "#d24c4d",
                },
              },
              areaStyle: {
                color: "rgba(203, 73, 74, 0.5)",
              },
              data: newData.series2,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
